var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.files.length == 0
      ? _c("div", [_vm._v("No Files")])
      : _c(
          "div",
          {
            staticStyle: { display: "flex", gap: "10px", "flex-wrap": "wrap" },
          },
          _vm._l(_vm.files, function (file) {
            return _c(
              "div",
              {
                key: file.fileId,
                staticClass: "preview-file-button",
                on: {
                  click: function ($event) {
                    return _vm.previewFileMixin(file.fileId)
                  },
                },
              },
              [_vm._v(" " + _vm._s(file.fileDescription) + " ")]
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }